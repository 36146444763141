<template>
  <v-container class="crud-content" fill-height>
    <ListLayout :title="title" :info="info" :buttonActions="buttonActions">
      <template v-slot:filter>
        <DataFilter @filter="changeFilter" :search="search" :filters="filters" />
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="header"
          :loading="loading"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="options"
          :server-items-length="totalElements"
          multi-sort
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.percentageByCategory`]="{ item }">
            <v-chip class="itens">{{ item.percentageByCategory.length }}</v-chip>
          </template>
          <template v-slot:[`item.commissionPercentageDefault`]="{ item }">
            <v-chip class="itens">{{ item.commissionPercentageDefault || 0 }}%</v-chip>
          </template>
          <template v-slot:[`item.actual`]="{ item }">
            <v-icon v-if="item.actual" color="var(--green-0)">mdi-check</v-icon>
            <v-icon v-else color="var(--red-0)">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="primary" v-if="isAdmin" @click="openDetails(item.id)">mdi-pencil</v-icon>
              <v-icon :color="item.canDelete ? 'primary' : ''" v-if="isAdmin" @click="openDelete(item.id, item.canDelete)">mdi-delete</v-icon>
              <v-icon color="primary" v-if="!isAdmin" @click="openDetails(item.id)">mdi-magnify-plus-outline</v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
    <AlertModal :showModal="showModal">
      <template v-slot:header>
        <div class="d-flex flex-column">
          Atenção!
        </div>
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <div class="mb-3">Todas as informações de regras de comissões deste seller serão perdidas com esta ação.</div>
          <div>Deseja mesmo excluir as regras de comissões?</div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn outlined color="primary" class="mr-3" @click="closeModal()">CANCELAR EXCLUSÃO</v-btn>
        <v-btn color="primary" @click="deleteCommissionRule()">SIM, QUERO EXCLUIR</v-btn>
      </template>
    </AlertModal>
  </v-container>
</template>

<script>
import ListLayout from '@/components/template/list/ListLayout';
import DataFilter from '@/components/template/list/DataFilter';
import { get } from '@/service/api';
import notification from '@/service/notification';
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import isAdmin from '@/utils/user-utils';
import { routerPush } from '@/service/kplaceRouter';
import { listCommissionRule, deleteCommissionRule } from '@/service/commission-rule/commission-rule-service';
import AlertModal from '@/components/modal/AlertModal';

export default {
  components: {
    DataFilter,
    ListLayout,
    AlertModal,
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      title: 'Regras de Comissão',
      buttonActions: isAdmin() ? [{ name: 'CADASTRAR REGRAS', action: this.newCCommissionRule }] : [],
      loading: false,
      showModal: false,
      commissionRuleId: null,
      info: '',
      isAdmin: isAdmin(),
      options: {},
      search: '',
      filters: [
        {
          name: 'categoryId',
          label: 'Aplicação',
          labelSelect: 'name',
          placeHolder: 'Selecione',
          type: 'CustomSelect',
          service: async (param, filter, itemsPerPage) => {
            const { data } = await get(`ui-integrator/category/fetch?size=${itemsPerPage}&filter=${param ? `${filter} = !${param}` : ''}`);
            data.register.push({ id: 'GERAL', name: 'Geral' });
            data.register.push({ id: 'MODALIDADE', name: 'Modalidade de pagamento' });
            return data;
          },
          filled: true,
        },
        {
          name: 'initialDateRule',
          label: 'Data inicial da vigência',
          type: 'date',
          filled: true,
        },
        {
          name: 'finalDateRule',
          label: 'Data final da vigência',
          type: 'date',
          filled: true,
        },
        {
          name: 'titleRule',
          label: 'Pesquisar',
          placeHolder: 'Pesquise pelo titulo da regra...',
          type: 'search',
          filled: true,
        },
      ],
      header: [
        { text: 'Cód. regra', value: 'id', sortable: true },
        { text: 'Título das regras', value: 'titleRule', sortable: true },
        { text: 'Descrição da aplicação', value: 'description', sortable: true },
        { text: 'Categorias vinculadas', value: 'percentageByCategory', sortable: false },
        { text: 'Tarifa venda', value: 'commissionPercentageDefault', sortable: true },
        { text: 'Data Inicial', value: 'initialDateRule', sortable: true },
        { text: 'Data Final', value: 'finalDateRule', sortable: true },
        { text: 'Vigente', value: 'actual', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const response = await listCommissionRule(page, itemsPerPage, this.query, sortBy, sortDesc);
        if (response.status !== 200) {
          notification(`${response?.response?.data?.message}`, 'error');
        } else {
          response.data.register = response.data.register.map((item) => ({ ...item }));
          this.content = response.data.register;
          this.totalElements = response.data.totalRegister;
        }
      } catch (e) {
        notification(`Erro ao realizar busca das regras de comissão : ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    async deleteCommissionRule() {
      try {
        const response = await deleteCommissionRule(this.commissionRuleId);
        this.closeModal();
        if (response.status !== 204) {
          notification(`${response?.response?.data?.message}`, 'error');
        } else {
          notification('A regra de comissão foi deletada com sucesso.', 'success');
          this.init();
        }
      } catch (e) {
        notification('Erro ao deletar a regra de comissão', 'error');
      }
    },
    validateDeleteButton(item) {
      return !item.canDelete;
    },
    newCCommissionRule() {
      routerPush('/commission-rule/forms');
    },
    openDetails(id) {
      routerPush(`/commission-rule/forms/edit/${id}`);
    },
    openDelete(id, canDelete) {
      if (canDelete) {
        this.commissionRuleId = id;
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
      this.commissionRuleId = null;
    },
  },
  async mounted() {
    // TODO: Aguardando Ajuste do back para colocar a ordenação inicial
    // this.options.sortBy.push('actual,submittedAtRule');
    // this.options.sortDesc.push(true, false);
    await this.init();
  },
};
</script>

<style lang="scss" scoped>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}

.itens.theme--light.v-chip {
  color: white;
  background: #f44fe3;
}
</style>
